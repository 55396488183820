.devmakell-usertap-sidepanel {
    position: fixed;
    z-index: 10002;
    width: 64px;
    height: 64px;
    right: 0;
    top: 50%;
    align-items: center;
}

.devmakeall-usertap-sidepanel-img {
    margin-top: 8px;
    margin-left: 8px;
    display: block;
}