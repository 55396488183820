.devmakell-usertap-fab-demo {
    position: fixed;
    z-index: 10002;
    right: 16px;
    bottom: 16px;
    background-color: #ff3366;
    width: 64px;
    height: 64px;
    border-radius: 50px;
    background: #ff3366;
    border: none;
    outline: none;
    color: #FFF;
    padding: 10px 10px 10px 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: .3s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  float: right;
  text-align: left;
  
}

.devmakell-usertap-fabtext-demo {
    font-size: 15px;
    margin-left: 10px;
    text-transform: uppercase;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.devmakell-usertap-fab-demo:active {
  background: #FFF;
  background-color: #FFF;
  
  height: 84px
}

.devmakell-usertap-fab-demo:hover {
  width: 180px;
}

.devmakell-usertap-fab-demo:hover .devmakeall-usertap-fab-img-demo {
    margin-right: 10px;
    margin-left: 5px;
  }

.devmakeall-usertap-fab-img-demo {
    float: right;
    margin: auto;
    display: block;
    margin-left: 30px;
    margin-right: 90px;
}

.devmakell-usertap-sidepanel-demo {
    position: fixed;
    z-index: 10002;
    width: 64px;
    height: 64px;
    left: 0;
    top: 50%;
}


.devmakeall-video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }

.devmakeall-video-container iframe, .devmakeall-video-container object, .devmakeall-video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }