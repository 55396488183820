.devmakeall-usertap-tooltip {
    position: absolute;
    bottom: 100px;
    right: 100px;
    display: inline-block;
    font-size: larger;
    font-weight: bold;
    border-bottom: 1px dotted black;
}
  
.devmakeall-usertap-tooltip .devmakeall-usertap-tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.devmakeall-usertap-tooltip:hover .devmakeall-usertap-tooltiptext {
    visibility: visible;
}