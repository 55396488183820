.devmakell-usertap-fab {
    position: fixed;
    z-index: 10002;
    right: 16px;
    bottom: 16px;
    background-color: #8320c5;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    background: #8320c5;
    border: none;
    outline: none;
    color: #FFF;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: .3s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
  justify-content: center;
  cursor: pointer;
}

.devmakell-usertap-fab:active {
    background: #FFF;
    background-color: #FFF;
    width: 84px;
    height: 84px
  }

  .devmakell-usertap-fab:hover {
    background: #FFF;
    background-color: #FFF;
  }

.devmakeall-usertap-fab-img {
    margin: auto;
    display: block;
}