.devmakell-usertap-overlay {
    z-index: 10001; 
    position: fixed;
    top: 90%;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.8;
    background-color: #000;
}
.devmakell-usertap-message {
    position: fixed;
    z-index: 10002;
    background: #fff;
    left: 50%;
    top: 95%;
    margin: 0 0 0 0;
    width: auto;
    padding: 10px 20px;
    border: solid 1px #333;
    border-radius: 10px;
}