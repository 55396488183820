.chatbubble {
    transform: translatey(0px);
    animation: float 5s ease-in-out infinite;
    mix-blend-mode: multiply;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 12px;
    color: white;
    background-color: #ff3366;
    padding: 15px;
    border-radius: 11px;
    position: relative;
    box-shadow: 20px 20px #83af9b;
    cursor: pointer;
    /* border: 1px solid $color-green; */
  }
  
  .chatbubble:after {
    transform: translatey(0px);
    animation: float2 5s ease-in-out infinite;
    content: ".";
    font-weight: bold;
    /* -webkit-text-stroke: 0.5px $color-green; */
    -webkit-text-fill-color: #ff3366;
    /* border: 1px solid $color-green; */
    text-shadow: 22px 22px #83af9b;
    text-align: left;
    font-size: 55px;
    width: 55px;
    height: 11px;
    line-height: 30px;
    border-radius: 11px;
    background-color: #ff3366;
    position: absolute;
    display: block;
    bottom: -30px;
    left: 0;
    box-shadow: 22px 22px #83af9b;
    z-index: -2;
  }
  
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  
  @keyframes float2 {
    0% {
      line-height: 30px;
      transform: translatey(0px);
    }
    55% {
      transform: translatey(-20px);
    }
    60% {
      line-height: 10px;
    }
    100% {
      line-height: 30px;
      transform: translatey(0px);
    }
  }
  

  
.devmakeall-usertap-tooltip-demo .devmakeall-usertap-tooltiptext-demo {
    visibility: hidden;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 90%; 
}

.devmakeall-usertap-tooltip-demo:hover .devmakeall-usertap-tooltiptext-demo {
    visibility: visible;
}