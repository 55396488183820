.devmakeall-usertap-likedislike-panel {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 10px;
    border-radius: 10px;
    display:inline-block;
}

.devmakeall-usertap-likedislike-panel:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.devmakeall-usertap-likedislike {
    cursor: pointer;
    font-size: larger;
}

.devmakeall-usertap-likedislike-one {
    cursor: pointer;
    font-size: larger;
    color: transparent;
    text-shadow: 0 0 0 gold;
}

.devmakeall-usertap-likedislike-two {
    cursor: pointer;
    font-size: larger;
    color: transparent;
    text-shadow: 0 0 0 gold;
}

.devmakeall-usertap-likedislike-text {
    color: black;
    font-family: sans-serif;
}