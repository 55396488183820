.devmakeall-usertap-fivestar-panel {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 10px;
    border-radius: 10px;
    display:inline-block;
}

.devmakeall-usertap-fivestar-panel:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.devmakeall-usertap-fivestar {
    color: gold;
    cursor: pointer;
    font-size: larger;
}

.devmakeall-usertap-fivestar-text {
    color: black;
    font-family: sans-serif;
}